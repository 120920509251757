/* global site */

// Import site modules
import ('./site/scroll-animations.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      if ('serviceWorker' in navigator) {
        console.log('Registering ServiceWorker...');
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/sw.js', {
                scope: "/"
            }).then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
                // Trim the caches on load
                navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
                    command: "trimCaches"
                });
           }).catch(function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
        });
      }

      // Activate GLightbox
      setTimeout(function() {
        GLightbox({
          touchNavigation: true,
          autoplayVideos: true,
          openEffect: 'zoom',
          closeEffect: 'fade',
          cssEfects: { // This are some of the animations included, no need to overwrite
              fade: { in: 'fadeIn', out: 'fadeOut' },
              zoom: { in: 'zoomIn', out: 'zoomOut' },
          },
          keyboardNavigation: false,
          touchNavigation: false,
          draggable: false
        });
      }, 1000);

      // Activate Flickity - Main Full Sliders
      if (document.querySelector('.main-carousel')) {
        setTimeout(function() {
          [].slice.call(document.querySelectorAll('.main-carousel')).forEach(function(sliderInstace) {

            var flkty = new Flickity( sliderInstace, {
              // options
              cellAlign: 'center',
              wrapAround: true,
              imagesLoaded: true,
              lazyLoad: true,
              prevNextButtons: true,
              pageDots: false,
              on: {
                change: function( index ) {
                  [].slice.call(sliderInstace.parentElement.querySelectorAll('.slide')).forEach(function(slide, slideIndex) {
                    if (slideIndex === index) {
                      slide.classList.add('is-selected');
                    } else {
                      slide.classList.remove('is-selected');
                    }
                  });
                }
              }
            });
          });
        }, 1000);
      }

      // Activate Flickity - Split small Sliders
      if (document.querySelector('.box-carousel')) {
        setTimeout(function() {
          [].slice.call(document.querySelectorAll('.box-carousel')).forEach(function(sliderInstace) {

            var flkty = new Flickity( sliderInstace, {
              // options
              cellAlign: 'center',
              wrapAround: true,
              imagesLoaded: true,
              lazyLoad: true,
              prevNextButtons: true,
              pageDots: false
            });
          });
        }, 1000);
      }

      // Mobile menu trigger
      document.querySelector('.mobile-menu-trigger').addEventListener('click', function(e) {
        document.querySelector('#page-container').classList.toggle('is-menu-active');
      });

      document.querySelector('#content-container').addEventListener('click', function(e) {
        document.querySelector('#page-container').classList.remove('is-menu-active');
      });
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
